import React, { useState } from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/user-icon3.png";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';
import ChangePassword from "../../modal/ChangePassword";

const Header = ({ onNote }) => {
  const [changePassword, setChangePassword] = useState(false);
  const onFullScreen = () => {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };
  return (
    <>
    <ChangePassword  password= {changePassword} onHide ={() => setChangePassword(false)}/>
    <div className="header" style={{ zIndex: "11" }}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
          
            </div>
            <ul className="navbar-nav header-right">
             
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="i-false c-pointer nav-link"
                  to=""
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-info">
                    <span>
                      <strong>Admin</strong>
                    </span>
                  </div>
                  <img src={profile} width={20} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="dropdown-menu dropdown-menu-right"
                >
                  <Link className="dropdown-item ai-icon" onClick={()=> setChangePassword(true)} style={{backgroundColor:"#fff"}}>
                    <svg
                      id="icon-password"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ml-2">Change Password </span>
                  </Link>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    </>
  );
};

export default Header;

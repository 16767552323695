export const GET_USERS = 'admin/users';

export const DELETE_USERS = 'admin/deleteUser';

export const BLOCK_USERS = 'admin/userAction';

export const APPROVE_USER_DETAILS = 'admin/auth/userApproved';


export const GET_CODES = 'admin/zipCodeList';

export const DELETE_CODE = 'admin/deleteZipCode';

export const ADD_CODE = 'admin/createZipCode';


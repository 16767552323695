import axiosInstance from "../AxiosInstance";
import {
  ADD_CODE,
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_CODE,
  DELETE_USERS,
  GET_CODES,
  GET_USERS,
  POST_USER_DETAILS,
} from "./UserApiEndPoints";
export function getAllUsers(currentPage, limit, search, type) {
  return axiosInstance.get(
    GET_USERS +
      `?page=${currentPage}&limit=${limit}&search=${search}&type=${type}`
  );
}
export function deleteUser(id) {
  const postData = {
    userId: id,
  };
  return axiosInstance.put(DELETE_USERS, postData);
}
export function blockUser(id, actionType) {
  const postData = { userId: id, isBlocked: actionType };
  return axiosInstance.put(BLOCK_USERS, postData);
}
export function getCodes(currentPage, limit) {
  return axiosInstance.get(GET_CODES + `?page=${currentPage}&limit=${limit}`);
}
export function deleteCode(code) {
  return axiosInstance.delete(DELETE_CODE + `?code=${code}`);
}
export function addCode(code) {
  const postData = {
    codes: code,
  };
  return axiosInstance.post(ADD_CODE, postData);
}

import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { deleteCode, getCodes } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Pagination from "../common/Pagination";
import AddCode from "../modal/AddCode";

export default function CodesManagement() {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 20;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getCodes(currentPage, limit);
      console.log(response.data.data);
      setUsers(response.data.data?.data);
      const total = response.data.data?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data?.total);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  ///function to delete data ///
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteCode(id);
      getTableData();
      notifyTopRight("Deleted Successfully!");
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, [currentPage]);
  return (
    <div>
      <PageTitle activeMenu="Zip Code List" motherMenu="Zip Codes" />
      <Card>
        <Card.Header className="d-block">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary text-black py-2"
              onClick={() => setShowModal(true)}
            >
              Add +
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>Code</strong>
                  </th>

                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>{item}</td>

                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dark light"
                          className="light sharp btn btn-dark i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => onDelete(item)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {showModal && (
        <AddCode
          show={showModal}
          table={getTableData}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
//import logo from '../../images/logo-full.png'
import { ToastContainer, toast } from "react-toastify";
import { changePasswoard } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { addCode } from "../../services/User/UserService";
export default function AddCode({ show, table, onHide }) {
  //Modal box
  let errorsObj = { code: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [code, setCode] = useState([]);
  const [newText, setNewText] = useState("");
  const [currentEditIndex, setCurrentEditIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const handleAddText = (e) => {
    e.preventDefault();
    const newArr = code;
    if (newText.trim() && newText != "") {
      newArr.push(newText.trim());
    }
    setCode(newArr);
    setNewText("");
  };
  const handleUpdateText = () => {
    // ...
    const updatedReference = [...code];
    updatedReference[currentEditIndex] = newText;
    setCode(updatedReference);
    setNewText("");
    setIsEdit(false);
  };
  const handleEdit = (index, item) => {
    // Set newText state to the item being edited
    setNewText(item);
    // Set the currentEditIndex state to the index of the item being edited
    setCurrentEditIndex(index);
    // Set isEdit state to true to indicate that we're in edit mode
    setIsEdit(true);
  };
  const handleDelete = (index) => {
    const newReferences = [...code];
    newReferences.splice(index, 1);
    setCode(newReferences);
  };
  async function onSubmitCode(e) {
    console.log("ffffffff");
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (code.length === 0) {
      errorObj.code = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const formattedCodes = code.map((code) => ({ code }));
      const response = await addCode(formattedCodes);
      notifyTopRight(response.data?.message);
      table();
      onHide();
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      notifyError(error.response.data?.message);
    } finally {
    }
  }
  return (
    <Modal className="modal fade" show={show} centered onHide={onHide}>
      <div className="" role="document">
        <div className="">
          <form onSubmit={onSubmitCode}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add Code</h4>
              {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Code</label>
                    {code.length > 0 && (
                      <div className="ml-4 mb-2">
                        <ul>
                          {code.map((item, index) => (
                            <div
                              className="d-flex justify-content-between"
                              key={index}
                            >
                              <li>{item} </li>
                              <div>
                                <i
                                  className="flaticon-381-edit-1 pointer"
                                  onClick={() => handleEdit(index, item)}
                                ></i>
                                <i
                                  className="flaticon-381-trash-1 pointer ml-2"
                                  onClick={() => handleDelete(index)}
                                ></i>
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="contact-name d-flex">
                      <input
                        type="text"
                        className="form-control mr-2"
                        value={newText}
                        onChange={(e) => {
                          setNewText(e.target.value);

                          setErrors({ ...errors, code: "" });
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-color py-1 px-3"
                        onClick={isEdit ? handleUpdateText : handleAddText}
                      >
                        {isEdit ? "Update" : "Add"}
                      </button>
                    </div>
                    {errors.code && (
                      <div className="text-danger fs-12">{errors.code}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                // onClick={onChangePassword}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-secondary"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
